import { useState, useCallback } from "react"
import styled from "styled-components"
import { Icon, IconKey } from "@openui"
import { useDebounce } from "hooks/useDebounce"

export const MenuSearch = ({
  active,
  onClose,
  onSearchTermUpdate,
}: {
  active: boolean
  onClose: () => void
  onSearchTermUpdate: (searchTerm: string | null) => void
}) => {
  const [searchTerm, setSearchTerm] = useState<string | null>(null)
  useDebounce(() => onSearchTermUpdate(searchTerm), 300, [searchTerm])

  const handleClose = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()
      onClose()
      setSearchTerm("")
      onSearchTermUpdate(null)
    },
    [onClose, onSearchTermUpdate],
  )

  const handleOnChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((e) => {
    setSearchTerm(e.target.value)
  }, [])

  return (
    <MenuCategoryInputWrapper $active={active}>
      <MenuCategoryInput
        placeholder="Search"
        type="text"
        value={searchTerm ?? undefined}
        onChange={handleOnChange}
      />
      <Icon iconKey={IconKey.Close} size={24} onClick={handleClose} />
    </MenuCategoryInputWrapper>
  )
}

const MenuCategoryInputWrapper = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  max-width: 0px;
  opacity: 0;
  padding-right: 8px;
  pointer-events: none;

  transition:
    max-width 300ms ease-in-out,
    opacity 300ms ease-in-out;

  ${({ $active }) =>
    $active
      ? `
    pointer-events: all;
    max-width: 600px;
    opacity: 1;
  `
      : ``}
`

const MenuCategoryInput = styled.input`
  all: unset;
  min-width: 180px;
  margin: 0px 4px 0px 8px;
  cursor: text;
`
