import { useRouter } from "next/router"
import { MenuItemDetailModal } from "./index"
import { ModalController } from "@openui"

interface Props {
  controller: ModalController
  onAddItemToCart?: () => void
}

export const MenuItemDetailModalFromQuery = ({
  controller,
  onAddItemToCart,
}: Props) => {
  const router = useRouter()
  const { storeMenuId, menuItemId } = router.query
  return (
    <MenuItemDetailModal
      controller={controller}
      onAddItemToCart={onAddItemToCart}
      storeMenuId={storeMenuId as unknown as number}
      menuItemId={menuItemId as unknown as number}
    />
  )
}
