import Link from "next/link"
import styled from "styled-components"
import { useActiveStore } from "hooks/useActiveStore"
import { Button, Spacer, Text } from "@openui"

export const OrderFallback = () => {
  const { activeStore } = useActiveStore()
  const hasFallbackOption =
    activeStore?.fallbackOrderUrl != null && activeStore.fallbackOrderUrl != ""
  const description = hasFallbackOption
    ? `We are temporarily unable to process orders for ${activeStore.name}. You can still place an order through alternative platforms.`
    : `We are temporarily unable to process orders for ${activeStore?.name}. This store does not have a fallback ordering option.`
  return (
    <Container>
      <Inner>
        <Text style="DisplayHeading/Large">Use the link below to order.</Text>
        <Spacer size={2} />
        <Text style="Body/Regular" color="Content/Secondary">
          {description}
        </Text>
        {hasFallbackOption && (
          <>
            <Spacer size={2} />
            <UnstyledLink
              href={activeStore.fallbackOrderUrl!}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>Order here</Button>
            </UnstyledLink>
          </>
        )}
        <Spacer size={2} />
        <Link href="/locations">
          <Text>Change location</Text>
        </Link>
      </Inner>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`

const UnstyledLink = styled.a`
  text-decoration: none;
`
