import { useEffect, useRef } from "react"

export function useDebounce<D extends Array<T>, T>(
  effect: () => void,
  debounceTimeMs: number,
  deps: D,
) {
  const debounceRef = useRef(0)

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.clearTimeout(debounceRef.current)
      debounceRef.current = window.setTimeout(effect, debounceTimeMs)
    }
  }, deps)
}
