import { DaysOfTheWeekNames, Menu } from "@models/menu"
import { DaysOfTheWeek } from "@models/menuHours"
import { useActiveStore } from "hooks/useActiveStore"
import { MerchantConfigContext } from "providers/MerchantConfigProvider"
import { useContext } from "react"
import styled from "styled-components"

type Props = {
  menu: Menu
  timezone: string
}

const MenuHoursContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const MenuHoursRow = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-areas: "days hours";
`

const MenuDayContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  grid-area: days;
  margin-right: 8px;
`

const MenuHourContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  grid-area: hours;
`

const MenuHourItem = styled.span`
  display: inline-flex;
  align-items: flex-start;
`

export const MenuHoursList = ({ menu, timezone }: Props) => {
  const hours = menu.formatAllMenuHours(timezone)
  return (
    <MenuHoursContainer key={menu.id}>
      {[
        DaysOfTheWeek.Monday,
        DaysOfTheWeek.Tuesday,
        DaysOfTheWeek.Wednesday,
        DaysOfTheWeek.Thursday,
        DaysOfTheWeek.Friday,
        DaysOfTheWeek.Saturday,
        DaysOfTheWeek.Sunday,
      ].map((dayOfTheWeek) => {
        return (
          <MenuHoursRow key={`${menu.id}:${dayOfTheWeek}`}>
            <MenuDayContainer>
              {DaysOfTheWeekNames[dayOfTheWeek]}
            </MenuDayContainer>
            <MenuHourContainer>
              {hours[dayOfTheWeek].map((dayHours) => (
                <MenuHourItem key={dayHours}>{dayHours}</MenuHourItem>
              ))}
            </MenuHourContainer>
          </MenuHoursRow>
        )
      })}
    </MenuHoursContainer>
  )
}
