import apiClient from "@api/client"
import { QUERY_KEYS } from "@api/constants"
import { MenuItem } from "@models/menuItem"
import { useQuery } from "@tanstack/react-query"
import { useActiveMenu } from "./useActiveMenu"

export const getPopularItemsQuery = (menuId: number | null = null) => ({
  enabled: menuId != null,
  queryKey: [QUERY_KEYS.GET_POPULAR_MENU_ITEMS, menuId],
  queryFn: async () => {
    const response = await apiClient.get<any[]>(
      `menus/${menuId}/popular_items/`,
    )

    if (response?.statusCode !== 200) {
      return null
    }

    // TODO: probably change at some point
    return response.body
  },
  select: (data: Array<{ [key: string]: any }> | null) => {
    return data ? data.map(MenuItem.fromDynamic) : null
  },
})

export const usePopularMenuItems = () => {
  const activeMenu = useActiveMenu()
  return useQuery(getPopularItemsQuery(activeMenu.data?.id))
}
